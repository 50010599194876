<template>
  <div>
    <CCard>
      <CCardHeader>
        <CDropdown toggler-text="Select" class="m-1" color="primary">
          <CDropdownItem v-for="i of ChRange" :key="i" @click="onPlay(i)">{{ `CH ${i}` }}</CDropdownItem>
        </CDropdown>
      </CCardHeader>
      <CCardBody>
        <VideoWebRTCPlayer :target="target" />
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import axios from 'axios';
import utility from '@/utility.js';
import VideoWebRTCPlayer from "@/components/VideoWebRTCPlayer.vue";

const TEMP_DEV_ID = 'a8680683-ed7b-4c77-8165-9855d172ffa7';

export default {
  name: 'Monitor',

  components: {
    VideoWebRTCPlayer
  },
  data() {
    return {
      url: null,
      ch: null,
      target: null
    }
  },
  mounted() {
    this.onDevice(TEMP_DEV_ID);
  },
  computed: {
    ChRange() {
      const rtn = utility.Range(1, 17, 1);
      console.log(rtn);
      return rtn;
    }
  },
  methods: {
    onDevice: function(device_id) {
      axios.get(`/api/devices/${device_id}/webrtc/path/`)
        .then(result => {
          this.url = result.data.url;
          this.ch = 1;
          this.target = {
            url: result.data.url,
            ch: 1
          }
        })
        .catch(e => {
          console.error(e);
          this.url = null;
          this.ch = 1;
          this.target = null;
        });
    },
    onPlay: function(ch) {
      this.ch = ch;
      this.target = {
        url: this.url,
        ch: ch
      }
    }
  }
}
</script>
